import React, { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import './PubRanking.css'
import { Image, Input, Pagination, Spin, Table } from 'antd'
import { SupportedChainId } from '../../../constants/chains'
import { NavLink } from 'react-router-dom'
import { formatCurrency } from '../../../utils/formatBalance'
import { X20_VERSION } from '../../../constants/addresses'
import { ImageProps } from 'rebass'
import useInterval from '../../../hooks/useInterval'
import { AdvancedPic } from '../../../components/SelfConponets'

const { Column } = Table;

interface PubRankingProps {
    advanced: string
}

const { Search } = Input;
export const PubRanking = ({ advanced }: PubRankingProps) => {
    const API_SERVER = process.env.REACT_APP_API_SERVER;
    const { account, chainId } = useWeb3React()
    const x20Version = X20_VERSION;

    // useEffect(() => {
    //     const pathValue = getUrlParamParam('');
    //     if(pathValue.startsWith("0x") && pathValue.length == 42){
    //         localStorage.setItem(X20_INVITER_ADDR, pathValue);
    //         console.info('inviter=' + pathValue);
    //     }
    // }, [])

    const breakpointColumnsObj = {
        default: 5,
        1200: 4,
        1000: 3,
        768: 1,
    };

    const [x20ProjectList, setX20ProjectList] = useState([]);
    // const [pageNum, setPageNum] = useState(1)
    const [total, setTotal] = useState(0)
    const [keyword, setKeyword] = useState("");
    const [order, setOrder] = useState("1");
    const [current, setCurrent] = useState(1);
    const onChange = async (page: any) => {
        setCurrent(page);
        await fetchProjects(false, chainId, page, undefined);
    };

    const fetchProjects = useCallback(async (reset: boolean, chainIds: any, page: any, orders:any) => {
        try{
            if(reset){
                setCurrent(1);
                page = 1;
            }
            setLoading(true);
            let chain = 'BASE';
            console.info(account + ' ,chainId= ' + chainIds);
            if(chainIds === SupportedChainId.BASE){
                chain = 'BASE';
            }else  if(chainIds === SupportedChainId.MAINNET){
                chain = 'ETH';
            }else  if(chainIds === SupportedChainId.ARBITRUM_ONE){
                chain = 'ARB';
            }
            const pageSize = '20';
            let fullUrl = API_SERVER + '/v2api/x20/tokenList?chain='
              + '' +"&keyword=" + keyword + "&pageNum=" + (page ? page : current) + "&pageSize=" + pageSize + "&order=" + (orders? orders : order)+"&version=" +x20Version;
            if(advanced){
                fullUrl += '&advanced=' + advanced;
            }
            const list = await fetch(fullUrl)
            const resObj = await list.json()
            if (resObj['code'] === 200) {
                let newList = resObj['data'];
                newList.forEach((item: { chain: string }) => {
                    if(item.chain === 'WORLD'){
                        item.chain = 'World';
                    }else if(item.chain === 'BASE'){
                        item.chain = 'Base';
                    }else if(item.chain === 'ETH'){
                        item.chain = 'Ethereum';
                    }else if(item.chain === 'ARB'){
                        item.chain = 'Arbitrum';
                    }
                })
                setX20ProjectList(newList);
                setTotal(resObj['total']);
            }
            setLoading(false);
        }catch (e) {
            console.error(e);
            setLoading(false);
        }
    }, [setX20ProjectList, setTotal, x20ProjectList, setCurrent, current, keyword, account, advanced]);

    const onSearch = async (value:string) => {
        console.log("search", value);
        setKeyword(value);
        await fetchProjects(true, chainId, undefined, undefined);
    };

    const options = [
        {
            value: "1",
            label: "sort: featured 🔥",
        },
        {
            value: "2",
            label: "sort: creation time  ",
        },
        // {
        //     value: "3",
        //     label: "Trading Volume",
        // },
        // {
        //     value: "4",
        //     label: "24H Price Increase",
        // },
    ];

    const handleChange = async(value:any) => {
        console.log("select", value);
        setOrder(value);
        await fetchProjects(true, chainId, undefined, value);
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.info("init read project data");
        fetchProjects(true, chainId, undefined, undefined);
    }, [chainId, advanced])

    return (
        <div className="PubRankingArea">
            {/*<div className="home_header">
                <div className="home_header_search">
                    <Search
                        placeholder="Search for tokens"
                        prefix={<img src={search} />}
                        allowClear={{
                            clearIcon: <CloseOutlined style={{ color: "white" }} />,
                        }}
                        onSearch={onSearch}
                        className="home_header_search_input"
                    />
                </div>
                <div className="home_header_select">
                    <Select
                        defaultValue={order}
                        onChange={handleChange}
                        options={options}
                        className="home_header_select_input"
                        popupClassName="home_header_select_popup"
                    />
                </div>
            </div>*/}


            <div className="rankingTable">

                <Table id="rankingTable" dataSource={x20ProjectList} pagination={false} >

                    <Column title="" dataIndex="avatar" key="orders"
                            render={(_: any, record: any) => (
                              <div className="table_time">
                                  {
                                    <div>{record.orders}</div>
                                  }
                              </div>
                            )}
                    />

                    <Column title="Logo" dataIndex="avatar" key="avatar"
                            render={(_: any, record: any) => (
                              <div className="table_time">
                                  <Image
                                    width={20}
                                    className="rule_img"
                                    src={record.image}
                                  />
                              </div>
                            )}
                    />
                    <Column title="Name (Symbol)" dataIndex="creator" key="creator"
                            render={(_: any, record: any) => (
                              <div className="column-token-name">
                                  <NavLink to={'/info?token=' + record.token}>
                                  {record.name + ' (' + record.symbol + ')'}
                                  </NavLink>
                              </div>
                            )}
                    />

                    <Column title="Contributors" dataIndex="userTotal" key="userTotal"
                            render={(_: any, record: any) => (
                              <div className="table_time">
                                  <NavLink to={'/log?token=' + record.token}>
                                      <div className="contributors-icon">{record.userTotal + '/' + (record.advanced === 2 ? 100 : record.advanced === 3 ? 1000 : 100000)} { record.advanced === 3 && <AdvancedPic/>}</div>
                                  </NavLink>
                              </div>
                            )}
                    />

                    <Column title="Price" dataIndex="userTotal" key="userTotal"
                            render={(_: any, record: any) => (
                              <div className="table_time column-wid-80">
                                  {
                                      record.price ? <div className="contributors">{formatCurrency(record.price)}</div> : <div/>
                                  }
                              </div>
                            )}
                    />

                    <Column title="On Chain" dataIndex="chain" key="chain"
                            render={(_: any, record: any) => (
                              <div className="column-wid-80">
                                  {
                                      record.chain
                                  }
                              </div>
                            )}
                    />

                    {/*<Column title="Withdrawal link" dataIndex="tokenAddress" key="tokenAddress"
                            render={(_: any, record: any) => (
                              <div className="table_copy">
                                  <NavLink to={'/i/' + record.token}>
                                      <div className="to_withdraw">To Withdraw</div>
                                  </NavLink>
                              </div>
                            )}
                    />*/}
                </Table>


            </div>


            <div className="home_more">
                {loading ? (
                    <Spin />
                ) : (
                  <div className="pageDiv">
                      <Pagination
                        total={total}
                        defaultPageSize={20}
                        defaultCurrent={1}
                        current={current}
                        onChange={onChange}
                        showSizeChanger={false}
                      />
                  </div>

                  /*<Button
                      type="primary"
                      className="home_more_button"
                      onClick={moreButton}
                  >
                      Load More
                  </Button>*/
                )}
            </div>
        </div>
    );
};
export default PubRanking;
