import { useState } from 'react'
import { Slash } from 'react-feather'
import { ImageProps } from 'rebass'
import styled from 'styled-components/macro'
import meme from '../assets/x20/meme.png'
import logo from '../assets/img/logo.png'


const BateText = styled.div`
  color: gray;
  font-weight: bold;
  margin-left: .5rem;
  font-size: 10px;
  margin-top: .4rem;
`

/**
 * Renders an image by sequentially trying a list of URIs, and then eventually a fallback triangle alert
 */
export  function BateTexts() {
  return <BateText>Beta</BateText>
}

export  function AdvancedPic() {
  return <img src={ meme} alt="" style={{ width: '.8rem', height: '.8rem', marginLeft: '.2rem', marginRight: '.2rem' }} />
}
