import { Input, message, Modal, Pagination, Progress, Table } from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import './PubTokenInfo.css'
import useInterval from '../../../hooks/useInterval'
import BigNumber from "bignumber.js";
import {
  formatCurrency,
  formatCurrency2,
  getBalanceNumber,
  getDisplayBalance,
  toBigString
} from '../../../utils/formatBalance'

import useTokenApprove from "../../../hooks/useTokenApprove";
import {useWeb3React} from "@web3-react/core";
import {
  getBaseToken,
  getBaseTokenName,
  useBaseToken, useSwapV2Router,
  useX20Factory,
  useX20Pool,
  useX20Token
} from '../../../hooks/useContract'
import { close, NULL_ADDRESS, X20_VERSION } from '../../../constants/addresses'
import { formatAddress, formatLastTime, formatStrShow, formatUrl, TimeHMS } from '../../../utils/time'
import { getUrlParamParam, getUrlParams } from '../../../utils'
import {eth_location, ic_copy} from "../../../assets";
import {NavLink} from "react-router-dom";
import { isMobile } from 'utils/userAgent'
// @ts-ignore
import {CopyToClipboard} from "react-copy-to-clipboard";
import { useChain } from 'react-spring'
import { ExplorerDataType, getExplorerLink } from '../../../utils/getExplorerLink'
import { SupportedChainId } from '../../../constants/chains'
import useTokenApproves from '../../../hooks/useTokenApproves'
import InfoSwap from './InfoSwap/InfoSwap'
import JoinTokenBtn from './JoinTokenBtn'
import { ca } from 'make-plural/plurals'
import { AdvancedPic } from '../../../components/SelfConponets'
const { TextArea } = Input;
const { Column } = Table;
const PubTokenInfo = () => {
  const { account, chainId } = useWeb3React()
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const x20Version = X20_VERSION;

  const [tokenInfo, setTokenInfo] = useState({id:0, name:"", price:'', symbol:"", token: "", address:"", image: "", desc:"", website: "", warpcaster: "", telegram: "", twitter: "", advanced:1});
  const [tokenAddress, setTokenAddress] = useState('');
  const [tokenName, setTokenName] = useState('');

  const [swapOpen, setSwapOpen] = useState(false);
  const [goonVisible, setGoonVisible] = useState(false);
  const showDialog = () => {
    console.info('2222');
    setGoonVisible(true);
  };
  const dismissDialog = () => {
    setGoonVisible(false);
  };

  const handleCopy = () => {
    const text = API_SERVER + '/info?token=' + tokenAddress+'&inviter='+ account;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          message.success('Copy successfully');
          dismissDialog();
        })
        .catch(err => {
          message.error('Failed to copy')
          dismissDialog();
        });
    } else {
      // 回退方案：使用 execCommand('copy') 兼容旧版浏览器
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'Copy successfully' : 'Failed to copy';
        message.success(msg)
        dismissDialog();
      } catch (err) {
        message.error('Failed to copy')
        dismissDialog();
      }
      document.body.removeChild(textarea);
    }
  };

  useEffect(() => {
    let tokenNameValue = '';
    if(chainId === SupportedChainId.BASE){
      tokenNameValue = "DEGEN";
    }else  if(chainId === SupportedChainId.MAINNET){
      tokenNameValue = "SHIB";
    }else  if(chainId === SupportedChainId.ARBITRUM_ONE){
      tokenNameValue = "CRYPTO";
    }else  if(chainId === SupportedChainId.WORLD){
      tokenNameValue = "WLD";
    }
    setTokenName(tokenNameValue);
    // console.info('init param chainId=' + chainId + ', tokenName=' + tokenNameValue);
  }, [ chainId, setTokenName])

  useEffect(() => {
    var token = getUrlParams()['token'];
    // console.info("Token tokenAddress==>" + token);
    setTokenAddress(token);
    readTokenInfo(token).then(r => {
      // console.info('read token succ');
    });

  }, [ setTokenInfo, setTokenAddress])

  const readTokenInfo = async (token: string) => {
    const list = await fetch(API_SERVER + '/v2api/x20/tokenInfo?token=' + token)
    const resList = await list.json()
    if (resList['code'] === 200) {
      setTokenInfo(resList['data']);
    }
  };

  const x20Factory = useX20Factory(x20Version);
  const x20Token = useX20Token(tokenAddress);
  const baseToken = useBaseToken();
  const [poolManagerAddr, setPoolManagerAddr] = useState('');
  const x20Pool = useX20Pool(poolManagerAddr);


  const [myLogList, setMyLogList] = useState([]);
  const [x20JoinUserList, setX20JoinUserList] = useState([]);

  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0)
  const onChange = (page: any) => {
    setCurrent(page);
  };

  const [transferFee, setTransferFee] = useState(1.2);
  const [currentX20, setCurrentX20] = useState(1);
  const [totalX20, setTotalX20] = useState(0)
  const onChangeX20 = (page: any) => {
    setCurrentX20(page);
  };
  const [bindInviter, setBindInviter] = useState("");
  const [inviter, setInviter] = useState("");
  useEffect(() => {
    var inviters = getUrlParams()['inviter'];
    // debugger
    // var inviters = localStorage.getItem(X20_INVITER_ADDR);
    if(!inviters){
      inviters = "0x0000000000000000000000000000000000000000";
    }
    if(account){
      if(inviters.toLowerCase() === account.toLowerCase()){
        inviters = "0x0000000000000000000000000000000000000000";
      }
    }
    console.info("inviter==>"+inviters);
    setInviter(inviters);
  }, [setInviter])

  const fetchLastTime = useCallback(async () => {
    try{
      if(account){
        const list = await fetch(API_SERVER + '/v2api/x20/walletLog?projectId='
            + tokenAddress +"&address=" + account + "&logPage=" + current + "&projPage=" + currentX20)
        const resList = await list.json()
        if (resList['code'] === 200) {
          const listRow = resList['data'];
          if(listRow){
            setMyLogList(listRow['logList']);
            setX20JoinUserList(listRow['userList']);
            setTotal(listRow['logTotal']);
            setTotalX20(listRow['userTotal']);
          }
        }
      }
    }catch (e) {
      console.error(e);
    }
  }, [  account, tokenAddress, current, currentX20, setMyLogList, setTokenAddress, setX20JoinUserList, setTotal, setTotalX20, setTokenInfo]);
  useInterval(fetchLastTime);

  //Mint
  const [copyStatus, setCopyStatus] = useState(false);
  const [copyInviteStatus, setCopyInviteStatus] = useState(false);
  const [copyTokenStatus, setCopyTokenStatus] = useState(false);
  const [readStatus, setReadStatus] = useState(false);
  const [needAuthnize, setNeedAuthnize] = useState(false);
  const [canJoin, setCanJoin] = useState(false);
  const [canWithdraw, setCanWithdraw] = useState(false);
  const [baseTokenAmount, setBaseTokenAmount] = useState('0');
  const [joinUserTotal, setJoinUserTotal] = useState(0);
  // const [lastWithdrawTime, setLastWithdrawTime] = useState(0);
  const [baseBalance, setBaseBalance] = useState(0);
  const [withdrawInfo, setWithdrawInfo] = useState(
      { available: "0" ,userWithdraw:"0"});

  const readInfos = useCallback(async () => {
    try{
      if(x20Token && baseToken && x20Factory){
        const cryptoAmounts = new BigNumber((await x20Factory.baseAmount()).toString());
        const cryptoAmount = getBalanceNumber(cryptoAmounts);
        const joinUserTotal = (await x20Token.joinUserTotal()).toString();
        setJoinUserTotal(joinUserTotal);
        setBaseTokenAmount(cryptoAmount.toString());
        // console.info('BaseTokenAmount===>' + cryptoAmount);

        if(account){
            const baseAllowance = getBalanceNumber(new BigNumber((await baseToken.allowance(account, tokenAddress)).toString()));
            const baseBalanceValue = getBalanceNumber(new BigNumber((await baseToken.balanceOf(account)).toString()));
            setBaseBalance(parseFloat(baseBalanceValue));

            const isUserJoin = (await x20Token.userMintStatus(account)).toString() === 'true';
            const isSwapOpen = (await x20Token.isOpen()).toString() === 'true';
            setSwapOpen(isSwapOpen);
            console.info("isSwapOpen=" + isSwapOpen);

            const needAuthCrypto = parseFloat(cryptoAmount.toString()) > parseFloat(baseAllowance.toString());
            // const lastWithdrawTime = parseInt((await x20Token.getWithdrawTime(account)).toString());
            // console.info("baseAllowance=" + baseAllowance + ',baseBalance=' + baseBalanceValue);

            // debugger
            // const lpAddr = await x20Token.baseToken();
            // console.info("baseToken=" + lpAddr);

            const poolManager =  (await x20Token.poolManager()).toString();
            setPoolManagerAddr(poolManager);

            if(x20Pool){
              const myInviter = await x20Pool.inivtes(account);
              setBindInviter(myInviter);
            }
          // const lpAddr2 = await x20Token.getPairAddress();
          // console.info("lpAddr2=" + lpAddr2);

          // const baseToken2 = await x20Token.baseToken();
          // console.info("baseToken=" + baseToken2);
          // debugger


            // const startTime = parseInt((await x20Token.startTime()).toString());

            // const transferFee = parseInt((await x20Token.transferFee()).toString());
            // console.info("transferFee=" + transferFee);
            // setTransferFee(transferFee);

            setCanJoin(!isUserJoin);
            setNeedAuthnize(needAuthCrypto);
            // setLastWithdrawTime(lastWithdrawTime);

            const joinList = await x20Factory.getJoin(account);
            // console.info(joinList);
            let withdrawInfo = {available: "0" ,userWithdraw:"0"};
            for (let i=0; i< joinList.length; i++){
              const rewardInfoObj = joinList[i];
              const available = getDisplayBalance(new BigNumber(rewardInfoObj.available.toString()));
              const userWithdraw = getDisplayBalance(new BigNumber(rewardInfoObj.userWithdraw.toString()));
              // console.info('available=' + rewardInfoObj.available + 'userWithdraw=' + rewardInfoObj.userWithdraw);
              // console.info( rewardInfoObj.tokenAddress +' |available=' + available + ' |userWithdraw=' + userWithdraw);
              if(rewardInfoObj.tokenAddress.toString().toLowerCase() === tokenAddress.toLowerCase()){
                withdrawInfo = {available: available ,userWithdraw:userWithdraw}
              }
            }
            setWithdrawInfo(withdrawInfo);
            if(withdrawInfo.available){
              try{
                setCanWithdraw(parseFloat((""+withdrawInfo.available).replaceAll(",", "")) > 0);
                }catch (e) {
                }
            }
            setReadStatus(true);
        }
      }
    }catch (e) {
      console.error(e);
    }
  }, [baseToken, x20Token, x20Factory, setNeedAuthnize, setCanJoin, setJoinUserTotal, setBaseTokenAmount, setWithdrawInfo,
    setCanWithdraw, setBaseBalance, setReadStatus, setTransferFee, setPoolManagerAddr, x20Pool, setBindInviter, setSwapOpen]);
  useInterval(readInfos);
  useEffect(() => {
    readInfos().then(r => {console.info("init read succ");});
  }, [])


  const baseTokenAddr = getBaseToken();
  const baseAuth = '500' + '000000000000000000';
  const {doApproves} = useTokenApproves(baseTokenAddr, tokenAddress);

  const handleApprove = () => {
    doApproves(toBigString(baseTokenAmount));
  }

  const baseTokenName = getBaseTokenName();
  const doJoin = () => {
    if(baseBalance < parseFloat(baseTokenAmount)){
      message.error('Insufficient '+baseTokenName+' token balance');
    }else{
      x20Token!.userMint(inviter);
    }
  }

  const doWithdraw = () => {
    x20Factory!.releaseTokens(tokenAddress);
  }

  const bornPool = () => {
    x20Factory!.bornPool(tokenAddress);
  }

  const copyOk = () => {
    setCopyStatus(true);
    message.success('Copy successfully')
  }

  const copyTokenOk = () => {
    setCopyTokenStatus(true);
    message.success('Copy successfully')
  }

  const test = async () => {
    // x20Token!.setTest();
    // x20Token!.buySwap();
    // const xx = await sha256("data:,190890.eth");
    // console.info("xxx===> " + xx);
    // message.success(xx);
    // x20Factory!.setSwapRouter('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D');
    // const x = await x20Factory!.swapRouter();
    // console.info('swapRouter===>'+x);
    const x = await x20Factory!.setDev(false);
    console.info('random===>'+x);

    // if(swapV2Router){
    //   const timestampInSeconds = Math.floor(Date.now() / 1000) + 1200;
    //   await swapV2Router.addLiquidity(baseTokenAddr, tokenAddress, 10000,10000000, 0,0, '0x0000000000000000000000000000000000000001', timestampInSeconds);
    // }

    // await x20Token!.swap(true, '1000000000000000000');
  }



  return (
        readStatus? (
          <div className="PubTokenInfoDiv">
            <div className="fast-border">
              <div className="fast-item">
                <div className="box_logo">
                  <img onClick={bornPool} className="rounded-full" src={tokenInfo.image} alt="" />
                  <div className="contact-div">

                    <div className="base-info">
                      {tokenInfo.name}&nbsp;({tokenInfo.symbol})
                    </div>
                    {
                      tokenInfo.price ? <div className="base-price-info">
                        {formatCurrency2(tokenInfo.price)}
                      </div> : <div />
                    }


                    <div className="contact-info">
                      <div className="contact-text">
                        <div onClick={test} className="value">{formatStrShow('Contract: ' + tokenAddress)}</div>
                      </div>
                      <div className="contact-btn">
                        <CopyToClipboard text={tokenAddress} onCopy={copyOk}>
                          {
                            copyStatus ?
                              <Progress className="copy-btn modal_progress" type="circle" percent={100}
                                        width={20} /> :
                              <img className="copy-btn" src={ic_copy} alt="" />
                          }
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div className="contact-info">
                      <div className="contact-text">
                        {/*<div className="name"></div>*/}
                        <div className="value">{formatStrShow(tokenName + ': ' + baseTokenAddr)}</div>
                      </div>
                      <div className="contact-btn">
                        <CopyToClipboard text={baseTokenAddr} onCopy={copyTokenOk}>
                          {
                            copyTokenStatus ?
                              <Progress className="copy-btn modal_progress" type="circle" percent={100}
                                        width={20} /> :
                              <img className="copy-btn" src={ic_copy} alt="" />
                          }
                        </CopyToClipboard>
                      </div>
                    </div>

                    {account ?
                      <div className="contact-info">
                        <div className="contact-text">
                          {/*<div className="name"></div>*/}
                          <div className="value">{formatStrShow('Invite Link: ' + API_SERVER + '/' + account)}</div>
                        </div>
                        <div className="contact-btn">
                          {
                            copyInviteStatus ?
                              <Progress className="copy-btn modal_progress" type="circle" percent={100}
                                        width={20} /> :
                              <img className="copy-btn" src={ic_copy} alt="" onClick={showDialog} />
                          }
                        </div>
                      </div> : <div />}

                    <div className="contact-info">
                      <div className="contact-text">
                        <div className="name">Contributors{ tokenInfo.advanced === 3 && <AdvancedPic/>}:&nbsp; </div>
                        <div className="value">{joinUserTotal  + '/' + (tokenInfo.advanced === 2 ? 100 : tokenInfo.advanced === 3 ? 1000 : tokenInfo.advanced === 1 ? 100000 : '')} addresses
                        </div>
                      </div>
                      <div className="contact-btn">
                        <NavLink className="detail-btn" to={'/log?token=' + tokenAddress}>Detail</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                isMobile ? <JoinTokenBtn
                  readStatus={readStatus}
                  canJoin={canJoin}
                  needAuthnize={needAuthnize}
                  canWithdraw={canWithdraw}
                  tokenAddress={tokenAddress}
                  handleApprove={handleApprove}
                  doJoin={doJoin}
                  doWithdraw={doWithdraw}
                  withdrawInfo={withdrawInfo}
                  tokenInfo={tokenInfo}
                  isSwapOpen={swapOpen}
                /> : <div />
              }

              <div className="space-left fast-item">
                <InfoSwap address={tokenAddress} name={tokenInfo.name} logo={tokenInfo.image} />
              </div>
            </div>

            {
              !isMobile ? <JoinTokenBtn
                readStatus={readStatus}
                canJoin={canJoin}
                needAuthnize={needAuthnize}
                canWithdraw={canWithdraw}
                tokenAddress={tokenAddress}
                handleApprove={handleApprove}
                doJoin={doJoin}
                doWithdraw={doWithdraw}
                withdrawInfo={withdrawInfo}
                tokenInfo={tokenInfo}
                isSwapOpen={swapOpen}
              /> : <div />
            }


            <div className="social-info">
              <div className="desc">
                {tokenInfo.desc}
              </div>
              <div className="base">
                {
                  tokenInfo.warpcaster && <div className="item">
                    <a target="_blank" href={formatUrl(tokenInfo.warpcaster)}>[Warpcast]</a>
                  </div>
                }
                {
                  tokenInfo.website &&
                  <div className="item">
                    <a target="_blank" href={formatUrl(tokenInfo.website)}>[Website]</a>
                  </div>
                }
                {
                  tokenInfo.telegram &&
                  <div className="item">
                    <a target="_blank" href={formatUrl(tokenInfo.telegram)}>[Telegram]</a>
                  </div>
                }
                {
                  tokenInfo.twitter &&
                  <div className="item">
                    <a target="_blank" href={formatUrl(tokenInfo.twitter)}>[Twitter]</a>
                  </div>
                }
                {
                  chainId && tokenInfo.address &&
                  <div className="item">
                    <a target="_blank"
                       href={getExplorerLink(chainId, tokenInfo.address, ExplorerDataType.ADDRESS)}>[Creater]</a>
                  </div>
                }
              </div>
            </div>


            <div className="history">
              {/*<div className="type-title">
          <p>我的领取列表</p>
        </div>*/}
              <div className="table-list">
                <Table id="domainTable" dataSource={myLogList} pagination={false}>
                  <Column title="Amount" dataIndex="avatar" key="avatar"
                          render={(_, record: any) => (
                            <div className="table_amount">
                              {record.amount}
                            </div>
                          )}
                  />
                  <Column title="Withdrawn Time" dataIndex="createTime" key="createTime" />
                </Table>
              </div>
              <div className="pageDiv">
                <Pagination
                  total={total}
                  defaultPageSize={10}
                  defaultCurrent={1}
                  current={current}
                  onChange={onChange}
                  showSizeChanger={false}
                />
              </div>
            </div>


            <Modal
              visible={goonVisible}
              onCancel={dismissDialog}
              closable={false}
              centered={true}
              footer={null}
              className="CopyModal"
            >
              <div className="ModalTitle">
                Confirm to Copy the Invitation Link
              </div>
              <div className="ModalContent">
                For the inviter's address, only after the inviter has contributed an equal amount of small liquidity, that is, approved and paid a small amount of $CRYPTO or $DEGEN or $SHIB or $WLD, can the invitation link be used to invite other invitees, otherwise it will be invalid. In addition, it is important to note that you must inform the invitee which blockchain network your invitation link applies to.
              </div>
              <div className="ModalBtns">
                <div className="OneBtn CancelBtn" onClick={dismissDialog}>Cancel</div>
                <div className="OneBtn YesBtn" onClick={handleCopy}>Copy</div>
              </div>
            </Modal>

          </div>) : <div>
          {/*<div onClick={test}>2222</div>*/}
        </div>
  )
}
export default PubTokenInfo
