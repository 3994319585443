import React from 'react'
import { NavLink } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { formatUrl } from '../../../utils/time'
import { ExplorerDataType, getExplorerLink } from '../../../utils/getExplorerLink'
import { message } from 'antd'


interface JoinTokenProps {
  readStatus: boolean;
  canJoin: boolean;
  needAuthnize: boolean;
  canWithdraw: boolean;
  tokenAddress: string;
  withdrawInfo: { available: string; userWithdraw: string };
  tokenInfo: { desc: string; warpcaster: string, website:string,telegram:string,twitter:string,address:string,  };
  handleApprove: () => void;
  doJoin: () => void;
  doWithdraw: () => void;
  isSwapOpen: boolean;
}


export default function JoinTokenBtn({
                                        readStatus,
                                        canJoin,
                                        needAuthnize,
                                        canWithdraw,
                                        tokenAddress,
                                        handleApprove,
                                        doJoin,
                                        doWithdraw,
                                        withdrawInfo,
                                        tokenInfo,
                                        isSwapOpen
                                      }: JoinTokenProps) {
  const { account, chainId } = useWeb3React();

  return (
    <>
      <div className="handle-btn-div">
        {
          readStatus ? (
            account ? (
              canJoin ?
                (
                  isSwapOpen? (  <div className="do-btn">
                    <div className={'ens_handle_btn_disable'} onClick={() => {message.error('The maximum number of contributors has been reached.') }} >Full</div>
                  </div>) : (
                    needAuthnize ? (
                      <>
                        {/*<div className="text">The Amount </div>*/}
                      <div className="do-btn" onClick={handleApprove}>
                        <div className={'x20_comm_btn'}>Approve (Pay to Contribute)</div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/*<div className="text">To mine</div>*/}
                      <div className="do-btn" onClick={doJoin}>
                        <div className={'x20_comm_btn'}>Start to earn</div>
                      </div>
                    </>
                  )
                )
                ) :
                <>
                  <div className="do-btn" onClick={canWithdraw ? doWithdraw : () => {console.info('unable')}}>
                    <div
                      className={canWithdraw ? 'x20_comm_btn' : 'ens_handle_btn_disable'}>Withdrawn
                    </div>
                  </div>

                  <div className="total-info">
                    <div
                      className="available">Available:{withdrawInfo.available} Withdrawn:{withdrawInfo.userWithdraw}</div>
                    <div className="invited"><NavLink className="detail-btn" to={'/invite?token=' + tokenAddress}>The
                      addresses I
                      invited</NavLink></div>
                  </div>
                </>
            ) : <div />
          ) : <div />
        }
      </div>
    </>

  )
}
