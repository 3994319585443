import React, { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import './PubTokenPage.css'

import Masonry from 'react-masonry-css'
import PubTokenItem from './PubTokenItem'
import { Button, Input, Pagination, Select, Spin, Switch } from 'antd'
import { CloseOutlined, DownOutlined } from '@ant-design/icons'

import { search } from '../../../assets'
import { getUrlParamParam } from '../../../utils'
import { X20_INVITER_ADDR } from '../../../constants/Constants'
import { SupportedChainId } from '../../../constants/chains'
import { useNativeCurrencyBalances } from '../../../lib/hooks/useCurrencyBalance'
import { X20_VERSION } from '../../../constants/addresses'

const { Search } = Input;
export const PubTokenPage = () => {
    const API_SERVER = process.env.REACT_APP_API_SERVER;
    const { account, chainId } = useWeb3React()
    const x20Version = X20_VERSION;

    useEffect(() => {
        const pathValue = getUrlParamParam('');
        if(pathValue.startsWith("0x") && pathValue.length == 42){
            localStorage.setItem(X20_INVITER_ADDR, pathValue);
            console.info('inviter=' + pathValue);
        }
    }, [])

    const breakpointColumnsObj = {
        default: 5,
        1200: 4,
        1000: 3,
        768: 1,
    };

    const [x20ProjectList, setX20ProjectList] = useState([]);
    // const [pageNum, setPageNum] = useState(1)
    const [total, setTotal] = useState(0)
    const [keyword, setKeyword] = useState("");
    const [order, setOrder] = useState("2");
    const [current, setCurrent] = useState(1);
    const onChange = async (page: any) => {
        setCurrent(page);
        await fetchProjects(false, chainId, page, undefined);
    };

    const fetchProjects = useCallback(async (reset: boolean, chainIds: any, page: any, orders:any) => {
        try{
            if(reset){
                setCurrent(1);
                page = 1;
            }
            setLoading(true);
            let chain = '';
            console.info(chainId + '   ' + account + ' ,chainId= ' + chainIds);
            if(account){
                if(chainIds === SupportedChainId.BASE){
                    chain = 'BASE';
                }else  if(chainIds === SupportedChainId.MAINNET){
                    chain = 'ETH';
                }else  if(chainIds === SupportedChainId.ARBITRUM_ONE){
                    chain = 'ARB';
                }else  if(chainId === SupportedChainId.WORLD){
                    chain = 'WORLD';
                }
            }
            const pageSize = '50';
            const list = await fetch(API_SERVER + '/v2api/x20/tokenList?chain='
                + chain +"&keyword=" + keyword + "&pageNum=" + (page ? page : current) + "&pageSize=" + pageSize + "&order=" + (orders? orders : order) +"&version=" +x20Version)
            const resObj = await list.json()
            if (resObj['code'] === 200) {
                let newList = resObj['data'];
                console.info("data length==>"+newList.length)
                setX20ProjectList(newList);
                setTotal(resObj['total']);
            }
            setLoading(false);
        }catch (e) {
            console.error(e);
            setLoading(false);
        }
    }, [setX20ProjectList, setTotal, x20ProjectList, setCurrent, current, keyword, account]);

    const onSearch = async (value:string) => {
        console.log("search", value);
        setKeyword(value);
        await fetchProjects(true, chainId, undefined, undefined);
    };

    const options = [
        {
            value: "1",
            label: "sort: featured 🔥",
        },
        {
            value: "2",
            label: "sort: creation time  ",
        },
        // {
        //     value: "3",
        //     label: "Trading Volume",
        // },
        // {
        //     value: "4",
        //     label: "24H Price Increase",
        // },
    ];

    const handleChange = async(value:any) => {
        console.log("select", value);
        setOrder(value);
        await fetchProjects(true, chainId, undefined, value);
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.info("init read project data");
        fetchProjects(true, chainId, undefined, undefined);
    }, [chainId])

    const onSwitchChange = (checked: boolean) => {
        console.log(`switch to ${checked}`);
    };

    return (
      <div className="PubTokenPageArea">

          <div className="home_v1">
              <a className="home_v1_btn" target="_blank" href="https://betav1.wedegen.com/popularity">BetaV1</a>
          </div>

          <div className="home_header">
              <div className="home_header_search">
                  <Search
                    placeholder="Search for tokens"
                    prefix={<img src={search} />}
                    allowClear={{
                        clearIcon: <CloseOutlined style={{ color: "white" }} />,
                    }}
                    onSearch={onSearch}
                    className="home_header_search_input"
                  />
              </div>
              {/*<Switch defaultChecked onChange={onSwitchChange} />*/}
              <div className="home_header_select">

                  <Select
                    defaultValue={order}
                    onChange={handleChange}
                    options={options}

                    // suffixIcon={<DownOutlined style={{ color: "white" }} />}
                    className="home_header_select_input"
                    popupClassName="home_header_select_popup"
                  />
              </div>
          </div>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="list"
            columnClassName="list_column"
          >
              {x20ProjectList.map((item, i) => (
                <PubTokenItem item={item} key={i} />
              ))}
          </Masonry>
          <div className="home_more">
              {loading ? (
                <Spin />
              ) : (
                <div className="pageDiv">
                    <Pagination
                      total={total}
                      defaultPageSize={50}
                      defaultCurrent={1}
                      current={current}
                      onChange={onChange}
                      showSizeChanger={false}
                    />
                </div>

                /*<Button
                    type="primary"
                    className="home_more_button"
                    onClick={moreButton}
                >
                    Load More
                </Button>*/
              )}
          </div>
      </div>
    );
};
export default PubTokenPage;
